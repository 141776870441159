import Icon from '@/components/icons/Icon';
import { roundRating } from '@/helpers';
import { themed } from '@/helpers/theme';
import { __ } from '@/locale';
import styles from './StarRating.module.scss';

const getStars = (rating: number) => {
  let empty = false;
  return [1, 2, 3, 4, 5].map((i, key) => {
    let fill = 100;
    if (i > rating) {
      fill = !empty ? (rating % 1) * 100 : 0;
      empty = true;
    }
    return (
      <div key={key} className="relative">
        <Icon variant="star-filled" color="black-200" style={{ width: 20, height: 20 }} />
        <Icon
          className="absolute inset-0"
          variant="star-filled"
          color="warning-500"
          style={{ width: 20, height: 20, clipPath: `inset(0 ${100 - fill}% 0 0)` }}
        />
      </div>
    );
  });
};

type Props = {
  rating: number;
  count?: number;
  justCount?: boolean;
  link?: boolean;
  totalScore?: number;
  showValue?: boolean;
  decimals?: number;
  /**
   * Mark it as an aggregate rating for schema.org
   */
  aggregateRating?: boolean;
  /**
   * Hide schema.org markup (to avoid multiple aggregateRating inside the same scope)
   */
  hideSchema?: boolean;
};

const StarRating = ({
  rating,
  count,
  justCount = false,
  link = false,
  totalScore = null,
  showValue = false,
  decimals = 1,
  aggregateRating = false,
  hideSchema = false,
}: Props) => {
  return (
    <div className={styles.rating}>
      <div
        className={styles.stars}
        {...(!hideSchema && { itemScope: true })}
        {...(!hideSchema &&
          aggregateRating && { itemProp: 'aggregateRating', itemType: 'https://schema.org/AggregateRating' })}
        {...(!hideSchema && !aggregateRating && { itemProp: 'reviewRating', itemType: 'https://schema.org/Rating' })}>
        {!hideSchema && <meta itemProp="ratingValue" content={'' + roundRating(rating, decimals)} />}
        {!hideSchema && aggregateRating && <meta itemProp="reviewCount" content={`${count}`} />}
        {showValue ? (
          <div className="text-black-600 flex text-sm font-semibold">
            {roundRating(rating, decimals)}
            <Icon
              className="inset-0 mr-1"
              variant="star-filled"
              color="warning-500"
              style={{ width: 20, height: 20 }}
            />
          </div>
        ) : (
          getStars(rating)
        )}
        {!showValue && count && (
          <span
            className={link ? `${styles.count} ${themed('!text-primary', '!text-black-800 underline')}` : styles.count}>
            ({justCount ? count : count + ' ' + __('ratings')})
          </span>
        )}
        {!count && totalScore && (
          <span
            className={link ? `${styles.count} ${themed('!text-primary', '!text-black-800 underline')}` : styles.count}>
            {rating}/{totalScore}
          </span>
        )}
      </div>
    </div>
  );
};

export default StarRating;
