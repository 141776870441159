import { ListItem } from '@/components/elements/lists';
import { CompanyAvatar } from '@/components/elements/redesign/Avatar';
import Icon from '@/components/icons/Icon';
import { Rating } from '@/components/modules/rating';
import { _s } from '@/locale';
import Card from '../Card';

type PlaceStatus = {
  status: 'open' | 'openSoon' | 'closed';
  openHours: string;
};

type LocationProps = {
  state: any;
  pathname: string;
};

type Image = {
  src: string;
  alt?: string;
};

type Address = {
  street?: string;
  city?: string;
  postal?: string;
};

type PlaceItemCardProps = {
  image?: Image;
  src?: LocationProps | string;
  title?: string;
  rating?: number;
  ratingCount?: number;
  address?: Address;
  businessHours?: PlaceStatus;
};

const PlaceItemCard = ({ image, src, title, rating, ratingCount, address, businessHours }: PlaceItemCardProps) => {
  return (
    <Card>
      <ListItem
        className="!items-start"
        leftSlot={<CompanyAvatar alt={image?.alt ?? ''} size="md" variant="default" imgUrl={image?.src ?? ''} />}
        onClick={() => {}}
        to={src}
        rightSlot={<Icon variant="chevron-s-right" />}>
        <div className="gap-xs flex flex-col" itemScope itemType="http://schema.org/LocalBusiness">
          {title && (
            <p itemProp="name" className="text-black-900 text-md truncate">
              {title}
            </p>
          )}
          {rating ? <Rating count={ratingCount} rating={rating} showRatingText={false} details starSize="sm" /> : null}
          {address && (
            <div
              itemProp="address"
              itemScope
              itemType="http://schema.org/PostalAddress"
              className="text-black-600 truncate">
              {address.street && <span itemProp="streetAddress">{`${address.street}, `}</span>}
              {address.postal && <span itemProp="postalCode">{`${address.postal}, `}</span>}
              {address.city && <span itemProp="addressLocality">{address.city}</span>}
            </div>
          )}
          {businessHours && (
            <p className={businessHours.status === 'open' ? 'text-primary-700' : 'text-danger-700'}>{`${_s(
              'placeCard.openToday',
            )}: ${businessHours.status === 'open' ? businessHours.openHours : _s('placeCard.closed')}`}</p>
          )}
        </div>
      </ListItem>
    </Card>
  );
};

export default PlaceItemCard;
