import 'core-js/es/array/find';
import 'core-js/es/map';
import 'core-js/es/number';
import 'core-js/es/object/assign';
import 'core-js/es/object/create';
import 'core-js/es/object/define-properties';
import 'core-js/es/object/define-property';
import 'core-js/es/object/freeze';
import 'core-js/es/object/get-own-property-descriptor';
import 'core-js/es/object/get-own-property-names';
import 'core-js/es/object/get-prototype-of';
import 'core-js/es/object/is';
import 'core-js/es/object/is-extensible';
import 'core-js/es/object/is-frozen';
import 'core-js/es/object/is-sealed';
import 'core-js/es/object/keys';
import 'core-js/es/object/prevent-extensions';
import 'core-js/es/object/seal';
import 'core-js/es/object/set-prototype-of';
import 'core-js/es/promise';
import 'core-js/es/set';
import 'core-js/es/string/repeat';
import 'core-js/es/symbol';
import 'core-js/es/symbol/iterator';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'whatwg-fetch';

import { createRoot } from 'react-dom/client';

import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import { Provider } from 'react-redux';
import App from './App';
import { config } from './config';
import { history, initAmplitudeAnalytics, isOldIE, isSentry } from './helpers';
import { AlertProvider } from './hooks/useAlert';
import { AmplitudeExperimentProvider } from './hooks/useAmplitudeExperiment';
import { LocationPermissionProvider } from './hooks/useLocationPermission';
import { LoginProvider } from './hooks/useLogin';
import { unregister as unregisterServiceWorker } from './registerServiceWorker';
import store from './store';

if (isSentry) {
  Sentry.init({
    dsn: config.sentry,
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
      }),
    ],
    tracesSampleRate: 0.001,
    normalizeDepth: 10,
  });
}

if (isOldIE) {
  window.location.replace('https://www.bokadirekt.se/outdated-browser.html');
}

initAmplitudeAnalytics();

const container = document.getElementById('root');
const root = createRoot(container);

setTimeout(
  root.render(
    <Provider store={store}>
      <AmplitudeExperimentProvider>
        <LocationPermissionProvider>
          <LoginProvider>
            <AlertProvider initialAlerts={[]}>
              <App />
            </AlertProvider>
          </LoginProvider>
        </LocationPermissionProvider>
      </AmplitudeExperimentProvider>
    </Provider>,
  ),
  0,
);
unregisterServiceWorker();
